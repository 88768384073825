<template>
    <div id="content" class="account-settings">
        <div class="bar-actions">
            <Button bType="cancel_changes" :bCallback="goBack" customClass="btn-spaced"></Button>
            <Button bType="save" :bCallback="saveExternalTool" customClass="btn-action" bLabel="menu_aux.action.save"></Button>
        </div>

        <div class="form-container" @click="initializeSnackbar()">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title" v-if="organizationRender">{{ organizationRender.name }}</h1>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <!-- <h2 class="label-form">{{ $t('settings.organization_name') }}</h2> -->

                        <!-- <p v-if="emptyAnswer.name" class="error-form" v-html="$t('library.form.error_name')"></p> -->

                        <div class="user-answer">
                            <!-- <Input v-model="organizationRender.name" :gMessage="{ default: 'Debes rellenarlo.', error: 'BAIA BAIA' }" @input="organizationRender.name" :gState="organizationRender.name == '' ? 'error' : ''"></Input> -->
                            <Input v-if="organizationRender" v-model="organizationRender.name" :gOptions="{ mandatory: true }" :gLabel="$t('settings.organization_name')" :gMessage="{ error: $t('library.form.error_name') }" :gState="emptyAnswer.name ? 'error' : ''" gSize="m"></Input>
                            <!-- <input type="text" v-model="organizationRender.name" class="input w85" autocomplete="off" name="name" :placeholder="$t('product.name_placeholder')" /> -->
                        </div>
                    </div>

                    <div class="right-container">
                        <h2 class="label-form">{{ $t('settings.logo') }}</h2>

                        <p class="description" v-html="$t('settings.logo_desc')"></p>

                        <div class="user-answer">
                            <ClipperImage v-if="clipper" :image="clipper" @getImage="showThumb"></ClipperImage>
                            <div class="icon-changes" v-if="icon && icon != 'null'">
                                <input type="file" id="icon_selector" style="display: none" ref="icon_product_selector" accept="image/*" @change="previewFiles($event, 'icon_product_selector')" />

                                <div v-if="icon" class="organization-logo" :style="{ backgroundImage: 'url(' + icon + ')' }"></div>

                                <Button bType="edit" :bCallback="selectIcon" bLabel="product.change_icon" customClass="btn-secondary"></Button>

                                <Button bType="delete" :bCallback="deleteIcon" bLabel="menu_aux.action.delete"></Button>
                            </div>

                            <div class="icon-changes" v-else>
                                <input type="file" id="icon_selector" style="display: none" ref="add_icon_product_selector" accept="image/*" @change="previewFiles($event, 'add_icon_product_selector')" />

                                <div class="organization-logo"></div>

                                <Button bType="create" :bCallback="addIcon" bLabel="settings.add_logo" customClass="btn-secondary"></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <!-- <h2 class="label-form">
                        {{ $t('settings.language') }}
                    </h2>

                    <p class="description" v-html="$t('settings.language_desc')"></p>

                    <p v-if="emptyAnswer.language" class="error-form" v-html="$t('settings.language_empty')"></p> -->

                        <div class="user-answer">
                            <Input v-if="organizationRender" :gOptions="{ returnObject: false, mandatory: true, itemValue: 'code' }" :gLabel="$t('settings.language')" :gHelp="$t('settings.language_desc')" :gState="emptyAnswer.language ? 'error' : ''" :gMessage="{ error: $t('settings.language_empty') }" gType="select" v-model="organizationRender.lang" :gItems="optionsLanguages ? optionsLanguages : {}" gSize="m"></Input>
                            <!-- <v-select hide-details class="input" v-if="optionsLanguages" v-model="organizationRender.lang" item-text="name" :items="Object.values(optionsLanguages)" item-value="code" :placeholder="$t('settings.select_lang')" dense :no-data-text="$t('supervise.filter.no_results')" :return-object="false"> </v-select> -->
                        </div>
                    </div>

                    <div class="right-container" v-if="optionsTimezones && organizationRender">
                        <!-- <h2 class="label-form">
                        {{ $t('settings.timezone') }}
                    </h2>

                    <p class="description" v-html="$t('settings.timezone_desc')"></p>

                    <p v-if="emptyAnswer.timezone" class="error-form" v-html="$t('settings.timezone_empty')"></p> -->

                        <Input v-if="organizationRender" :autocompleteValue="organizationRender.timezone ? organizationRender.timezone : false" :gOptions="{ returnObject: false, newInput: true, removable: false, referenceObject: optionsTimezones, mandatory: true, itemText: 'utc', itemValue: 'id' }" :gLabel="$t('settings.timezone')" :gHelp="$t('settings.timezone_desc')" :gState="emptyAnswer.timezone ? 'error' : ''" :gMessage="{ error: $t('settings.timezone_empty') }" gType="autocomplete" v-model="organizationRender.timezone" :gItems="Object.values(optionsTimezones)" gSize="m"></Input>

                        <!-- <Tags :tags="organizationRender.timezone" :referenceObject="optionsTimezones" nameTag="name" value="id" :removable="true"></Tags> -->
                        <!-- <v-autocomplete v-model="organizationRender.timezone" item-text="utc" item-value="id" :items="Object.values(optionsTimezones)" class="input w85"> </v-autocomplete> -->
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <Input v-if="organizationRender" @change="reloadComponent()" :autocompleteValue="organizationRender.languages ? organizationRender.languages : false" :gOptions="{ returnObject: false, multiple: true, newInput: true, removable: false, referenceObject: optionsTranslationLanguages, itemText: 'name', itemValue: 'code' }" :gLabel="$t('settings.translations_languages')" :gState="emptyAnswer.languages ? 'error' : ''" gType="autocomplete" v-model="organizationRender.languages" :gItems="Object.values(optionsTranslationLanguages)" gSize="m"></Input>
                    </div>
                </div>
            </div>
        </div>

        <div class="inline-delete" v-if="$route.params.id">
            <span class="text" @click="deleteExternalTool()">{{ $t('external_tools.delete') }}</span>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import ClipperImage from '@/components/domain/site/ClipperImage'

export default {
    name: 'OrganizationSettings',
    components: { ClipperImage },

    data() {
        return {
            editSuccess: false,
            clearTimeout: '',
            timeoutSnackbar: 5000,
            filenames: {},
            icon: false,
            category: '',
            sendForm: true,
            emptyAnswer: {
                name: false,
                language: false,
                timezone: false
            },
            form: false,
            newImage: false,
            clipper: false
        }
    },
    computed: {
        organizationRender() {
            if (this.$store.getters['account/getOrganization'] != undefined) {
                // var organization = { ...this.$store.getters['account/getOrganization'] }
                var org = this.$store.getters['account/getOrganization'].account

                if (org.config && org.config.languages && [...org.config.languages.split(',')].length > 0) {
                    org.languages = [...org.config.languages.split(',')]
                } else {
                    org.languages = []
                }

                // var org = { ...organization.account }
                if (org) {
                    if (org.avatar) {
                        this.icon = org.avatar
                    }
                }
                return org
            }
            return false
        },
        optionsTimezones() {
            var timezones = this.$store.getters['account/getOrganization']
            if (timezones) {
                return timezones.timezones
            }
            return {}
        },
        optionsLanguages() {
            var language = this.$store.getters['account/getOrganization']
            if (language) {
                return language.languages
            }
            return {}
        },

        optionsTranslationLanguages() {
            if (this.optionsLanguages && this.organizationRender && this.organizationRender.lang) {
                var langs = { ...this.optionsLanguages }
                delete langs[this.organizationRender.lang]
                return langs
            } else return {}
        }
    },
    methods: {
        reloadComponent() {
            this.$forceUpdate()
        },

        showThumb(image) {
            this.clipper = false
            this.thumbnail = image

            function dataURLtoFile(dataurl, filename) {
                var arr = dataurl.split(',')
                var mime = arr[0].match(/:(.*?);/)[1]
                var bstr = atob(arr[1])
                var n = bstr.length
                var u8arr = new Uint8Array(n)
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n)
                }
                return new File([u8arr], filename, { type: mime })
            }

            if (image) {
                this.createImage(dataURLtoFile(image, 'thumb_' + moment().valueOf() + '.png'))
                // this.file.thumb = dataURLtoFile(image, 'thumb_' + moment().valueOf() + '.jpg')
            } else {
                this.$refs.icon_product_selector.value = null
            }
        },

        initializeSnackbar() {
            // this.$snackbar.open({
            //   message: this.$t("supervise.title"),
            //   customClass: ""
            // });
            // <Snackbar tCustomClass="success" tLabel="settings.edit_success" :tDuration="timeoutSnackbar" ref="snackbar"></Snackbar>
        },

        selectIcon() {
            this.$refs.icon_product_selector.click()
        },
        addIcon() {
            this.$refs.add_icon_product_selector.click()
        },
        deleteIcon() {
            this.icon = false
        },
        previewFiles(e, name) {
            e.srcElement.nextSibling.src = '/img/file.svg'
            this.filenames = this.$refs[name].files[0].name
            // this.$overlay.loading();
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            // this.createImage(files[0], name)
            if (this.clipper) URL.revokeObjectURL(this.clipper)
            this.clipper = window.URL.createObjectURL(files[0])
        },
        // CREATION THUMB
        createImage(file, name) {
            var image = new Image()
            var reader = new FileReader()
            var self = this

            reader.onload = (e) => {
                image.onload = () => {
                    self.icon = resizeImage(image)
                }
                image.src = e.target.result
            }
            reader.readAsDataURL(file)
            this.newImage = true
        },

        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },

        load() {
            var self = this
            self.$overlay.show()
            var items = this.$store.getters['account/getOrganization']
            var filters = this.$store.getters['label/getFilterStatus']()
            var categories = this.$store.getters['label/getCategories']()
            if (Object.keys(items).length > 0 && Object.keys(categories).length > 0 && Object.keys(filters).length > 0) {
                self.$store.dispatch('account/loadListOrganization', {}).then(function () {
                    self.$overlay.hide()
                })
            } else {
                self.$store.dispatch('label/loadFiltersProducts', {}).then(function () {
                    self.$store.dispatch('account/loadListOrganization', {}).then(function () {
                        self.$overlay.hide()
                    })
                })
            }
        },

        saveExternalTool() {
            // console.log(this.$refs.groupDataSelected.selection);
            var self = this
            self.$overlay.loading()
            this.sendForm = true
            // set all values of errors to false
            this.emptyAnswer = _.mapValues(this.emptyAnswer, () => false)
            if (this.organizationRender.name === '') {
                this.sendForm = false
                this.emptyAnswer.name = true
            }
            if (this.organizationRender.lang == '' || this.organizationRender.lang == null) {
                this.sendForm = false
                this.emptyAnswer.language = true
            }
            if (this.organizationRender.timezone == '' || this.organizationRender.timezone == null) {
                this.sendForm = false
                this.emptyAnswer.timezone = true
            }
            if (!this.icon) {
                this.organizationRender.avatar = null
            }
            if (this.icon != false) {
                if (this.newImage) {
                    this.organizationRender.file = this.icon
                } else {
                    this.organizationRender.image = this.icon
                }
            } else if (!this.icon) {
                this.organizationRender.image = false
            }

            if (this.sendForm) {
                this.$store.dispatch('account/editOrganization', this.organizationRender).then(function (response) {
                    self.$overlay.hide()
                    self.icon = response.avatar
                    // self.$store.commit('changeLanguage', self.organizationRender.lang.slice(0, -3))
                    self.$snackbar.open({
                        message: self.$t('snackbar.edit_success') + ' <b>' + self.organizationRender.name + '</b>',
                        customClass: 'success',
                        duration: 5000
                    })
                })
            } else {
                self.$overlay.hide()
                scrollError()
            }
        },
        goBack() {
            window.history.back()
        }
    },
    created() {
        this.load()
    },
    updated() {},
    beforeUpdate() {},
    watch: {
        icon: {
            deep: true,

            handler() {
                this.$overlay.hide()
            }
        }
    },
    updated() {}
}
</script>

<style lang="scss">
#content.account-settings {
    .bar-actions {
        .btn-cancel_changes {
            margin-right: 0;
        }
    }

    .left-container {
        padding-left: 0;
    }
    // .input-top {
    //     @include display-flex();
    // }
    .organization-logo {
        @include background(
            $color: $color-neutral-200,
            //   $color: $success,
            $image: img('image_neutro.svg'),
            $size: 45px
        );
        @include border-radius(4px);
        width: 120px;
        height: 60px;
    }
    .icon-changes {
        @include display-flex();
        @include align-items();
    }
    .user-answer {
        height: auto !important;

        // .w100 {
        //     .label {
        //         max-width: unset;
        //         width: 100%;
        //     }
        // }
    }
    .prod-assigned-columns {
        @include display-flex();
        @include justify-content(inherit);
        padding-bottom: 30px;
        width: 90%;
        .column {
            @include font-size(md);
            font-family: $text-bold;
            width: 22.5%;
            padding-left: 15px;
        }
    }
    .add-state {
        @include background($image: img('add_neutro_s90.svg'), $size: 9px, $position: center left);
        @include font-size(md);
        font-family: $text-medium;
        width: fit-content;
        padding-left: 15px;
        cursor: pointer;
    }
}
</style>
