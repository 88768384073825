<template>
    <transition name="modal">
        <div class="msgBox">
            <div class="mask" @click="cancel()"></div>
            <div class="message">
                <div class="title-box">
                    <div class="title">{{ $t('settings.cut_logo') }}</div>
                    <div class="closeBtn" @click="cancel"></div>
                </div>
                <clipper-fixed class="my-clipper" ref="clipper" bg-color="transparent" :ratio="ratio" :src="image"></clipper-fixed>

                <div class="btns">
                    <!-- ZOOM IN -->
                    <div class="btn zoom-in" @click="zoomIn"></div>
                    <!-- ZOOM OUT -->
                    <div class="btn zoom-out" @click="zoomOut"></div>
                    <!-- TOOGLE RATIO -->
                    <div class="btn ratio" @click="toogleRatio"></div>
                </div>

                <div class="footerBtn">
                    <div class="btn secondary" @click="cancel">{{ $t('button.cancel') }}</div>
                    <div class="btn primary" @click="save">{{ $t('button.confirm') }}</div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
// import { clipperBasic } from 'vuejs-clipper'
import { clipperFixed } from 'vuejs-clipper'

export default {
    props: { image: String },
    data() {
        return {
            zoom: 1,
            ratio: 2 / 1
        }
    },
    created() {},
    computed: {},
    methods: {
        save() {
            const canvas = this.$refs.clipper.clip({ wPixel: 500 }) //call component's clip method
            var image = canvas.toDataURL('image/png', 0.8) //canvas->image
            this.$emit('getImage', image)
        },
        cancel() {
            this.$emit('getImage', false)
        },

        zoomIn() {
            this.zoom += 0.1
            this.$refs.clipper.setWH$.next(this.zoom)
        },
        zoomOut() {
            if (this.zoom > 0.4) {
                this.zoom -= 0.1
                this.$refs.clipper.setWH$.next(this.zoom)
            }
        },
        toogleRatio() {
            this.ratio = this.ratio === 2 / 1 ? 1 / 1 : 2 / 1
        }
    }
}
</script>

<style lang="scss" scoped>
.msgBox {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    z-index: 9999;
}
.title-box {
    @include display-flex();
    @include justify-content(space-between);
    @include align-items(center);
    padding: 10px;

    .title {
        @include font-size(md);
        font-family: $text !important;
        padding-bottom: 0;
        background: #fff;
    }
    .closeBtn {
        @include border-radius(50%);
        @include background($size: 20px, $image: img('close_dark.svg'));
        text-align: center;
        font-size: 18px;
        z-index: 999;
        height: 30px;
        width: 30px;
        cursor: pointer;
    }
}

.btns {
    @include border-radius(5px);
    position: absolute;
    top: calc(50% - ((60px * 3) / 2));
    right: 10px;
    background-color: #ccc;
    padding: 10px;

    .btn {
        @include border-radius(100%);
        text-align: center;
        margin-bottom: 10px;
        width: 50px;
        height: 50px;
        font-size: 33px;
        cursor: pointer;
        background-color: #fff;

        &:hover {
            background-color: #eee;
            transition: 0.2s;
        }

        &:last-child {
            margin-bottom: 0px;
        }

        &.zoom-in {
            @include background($size: 30px, $image: img('zoom_in_dark.svg'));
        }

        &.zoom-out {
            @include background($size: 30px, $image: img('zoom_out_dark.svg'));
        }

        &.ratio {
            @include background($size: 22px, $image: img('expand_dark.svg'));
        }
    }
}

.footerBtn {
    @include border-radius(4px);
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 0;
    margin-top: 20px;
    padding: 0 0;
    height: 50px;
    line-height: 50px;
    background-color: #fff;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    .btn {
        @include font-size(ml);
        width: 50%;
        float: left;
        font-family: $text-bold;
        text-align: center;

        &.primary {
            // background-color: $color-success-500;
            background-color: $color-primary-500;
        }
        &.secondary {
            background-color: $color-neutral-200;
        }
    }
}
.mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.my-clipper {
    width: 100%;
    height: 100%;
    // max-width: 700px;
    // max-height: 100%;
    max-height: calc(80vh - 100px);
    overflow: auto;
    margin: 0 auto;
    // padding-bottom: 50px;
}
.message {
    @include border-radius(3px);
    overflow: hidden;
    /* margin: 10px 0px; */
    padding: 0 0 !important;
    text-align: left;
    padding-left: 40px;
    font-size: 10pt;
    width: 50%;
    height: 80%;
    max-height: 80%;
    margin: 5% auto;
    background-color: #fff;
    position: relative;
}
</style>
